<template>
  <v-container class="d-flex align-center justify-center fill-height">
    <v-card class="d-flex align-center justify-center flex-column login-container dense">
      <img src="../assets/logo.png" class="logo mt-10">
      <span class="title mt-4">NSoft VNC</span>
      <p class="body-2 text-center grey--text text--darken-1 mt-4 mb-10">
        Welcome to NSoft VNC, internal application for managing Remote Desktop connections.
      </p>
      <div ref="googleLoginBtn"></div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({ login: "users/login", getuser: "users/getSelf" }),

    async handleCredentialResponse(response) {
      try {
        await this.login(response.credential);
        await this.getuser(response.credential);
      } catch (err) {
        console.error(err);
      }
    },

    onWindowLoad() {
      const gClientId = "344325573867-0btaekoosq3ondnrrbu42jn3s2uudg4t.apps.googleusercontent.com";
      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: this.handleCredentialResponse,
        auto_select: true
      });

      window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
        theme: "outline",
        size: "large",
        width: 366
      });
    }
  },

  mounted() {
    if (document.readyState === "complete") {
      this.onWindowLoad();
    } else {
      window.addEventListener("load", this.onWindowLoad);
    }
  },

  beforeDestroy() {
    // Cleanup the event listener to prevent potential memory leaks
    window.removeEventListener("load", this.onWindowLoad);
  }
};
</script>

<style lang="scss" scoped>
.login-container {
  padding-left: 64px;
  padding-right: 64px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-width: 440px;
  padding-bottom: 64px;

  .logo {
    width: 56px;
    height: 56px;
  }

  #googleLoginBtn {
    box-shadow: 0 2px 7px 0 rgba(34, 34, 34, 0.15);
    padding-top: 11px;
    padding-bottom: 11px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    img {
      width: 18px;
      height: 18px;
      margin-right: 22px;
    }
  }
}
</style>
