import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Tenants from '../views/Tenants.vue'
import Connections from "../views/Connections";
import Users from "../views/Users";

Vue.use(VueRouter)


let router = new VueRouter({
  mode: 'history',
  routes: [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isAuthRequired: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isAuthRequired: false
    }
  },
  {
    path: '/tenants',
    name: 'Tenants',
    component: Tenants,
    meta: {
      isAuthRequired: true
    }
  },
  {
    path: '/connections',
    name: 'Connections',
    component: Connections,
    meta: {
      isAuthRequired: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      isAuthRequired: true
    }
  },
]
})



router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.isAuthRequired)) {
    let user = sessionStorage.getItem("token");
    if (!user) {
      next("/login");
    }
  }
  next();
});


export default router



