<template>
  <div>
    
  <v-container fluid>
    <v-dialog v-model="addModal" width="600">
      <v-card>
        <v-card-title>Add new tenant</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="create">
            <v-text-field label="Company name" v-model="form.name">

            </v-text-field>
            <v-text-field label="Company UUID" v-model="form.uuid">
            </v-text-field>
            <div class="d-flex justify-end">
              <v-btn right color="primary" type="submit">Submit</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
        :headers="headers"
        :items="tenants"
        :search="searchQuery"
        :items-per-page="100"
        :page.sync="pagination.page"
        hide-default-footer
        class="elevation-1">
        <template v-slot:top>
        <v-text-field
          label="Search"
          dense
          class="pa-4"
          outlined
          v-model="searchQuery"
          @input="updateSearchQuery"
          slot="top"
        ></v-text-field>
      </template>
      <template v-slot:footer="{ props }">
        <v-data-footer :pagination="props.pagination" :options="props.options" disable-items-per-page @update:options="updatePagination" ></v-data-footer>
      </template>
    </v-data-table>
    <v-btn fab class="fab-bottom mb-16" bottom color="primary" @click="addModal = !addModal"><v-icon>mdi-plus</v-icon></v-btn>
  </v-container>
  
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({
    searchQuery: "",
    addModal: false,
    form: {
      name: "",
      uuid: ""
    },
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id'
      },
      {
        text: 'UUID',
        align: 'start',
        sortable: false,
        value: 'uuid'
      },
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Concurrent limit',
        align: 'start',
        sortable: false,
        value: 'limit_concurrent'
      },
      {
        text: 'Monthly limit',
        align: 'start',
        sortable: false,
        value: 'limit_monthly'
      },
      {
        text: 'Current monthly count',
        align: 'start',
        sortable: false,
        value: 'current_monthly'
      },
      {
        text: 'Connection time',
        align: 'start',
        sortable: false,
        value: 'connection_time'
      },
      {
        text: 'Created at',
        align: 'start',
        sortable: false,
        value: 'created_at'
      },
      {
        text: 'Updated at',
        align: 'start',
        sortable: false,
        value: 'updated_at'
      }]
  }),
  mounted() {
    this.getTenants()
  },
  watch: {
    searchQuery() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.runSearch();
      }, 400);
    },
  },
 
  methods: {
    ...mapActions({
      getTenants: "tenants/fetchTenants",
      createTenant: "tenants/createTenant"
    }),
    updatePagination(options) {
      this.getTenants({page: options.page})
    },
    create() {
      this.createTenant(this.form).then(() => {
        this.addModal = false;
        this.getTenants()
      })
    },
    runSearch() {
      if (this.searchQuery === "") {
        return this.$store.commit("tenants/SET_SEARCH_TENANTS","")
      } else {
        return this.$store.commit("tenants/SET_SEARCH_TENANTS", this.searchQuery)
      }
    },
    updateSearchQuery(val) {
    this.searchQuery = val;
  },
  },
  computed: {
    ...mapGetters({
      tenants: "tenants/getTenants",
      pagination: "tenants/getTenantsPagination"
    })
  },
  
}
</script>

<style scoped lang="scss">
.search-bar{
  height: 5%;
}
.fab-bottom {
  position: fixed;
  margin-right: 16px;
  bottom: 16px;
  right: 0;
  z-index: 5;
}
</style>
