const state = {
  settingsModal: false,
  currentEnvironment: "",
  environments: [
    {
      text: "Staging",
      value: "https://staging-vnc-api.nsoft.io/v1"
    },
    {
      text: "Production",
      value: "https://vnc-api.nsoft.io/v1"
    }
  ]
};
const getters = {
  currentEnvironment(state) {
    return state.currentEnvironment
  },
  settingsModal(state) {
    return state.settingsModal
  },
  environments(state) {
    return state.environments
  }
};
const mutations = {
  TOGGLE_SETTINGS_MODAL: (state) => {
    state.settingsModal = !state.settingsModal;
  },
  SET_ENVIRONMENT: (state, value) => {
    state.currentEnvironment = value;
  }
};
const actions = {
  setEnvironment({commit}, value) {
    commit('SET_ENVIRONMENT', value);
  },
  toggleSettingsModal({commit}) {
    commit('TOGGLE_SETTINGS_MODAL');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
