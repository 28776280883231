import axios from "axios"
import {rootUrls} from "@/assets/_constants";
import router from "@/router/index";
const state = {
  jwt: window.sessionStorage.getItem("token") || "",
  user: {},
  users: [],
}

const getters = {
  getUser(state) {
    return state.user
  },
  getToken(state) {
    return state.jwt
  },
  getUsers(state){
    return state.users
  }
}

const actions = {
  async getSelf({ commit, state }) {
    await axios.get(`${rootUrls.URL}/users/me`, {
      headers: {
        Authorization: `Bearer ${state.jwt}`
      }
    }).then(response => {
      commit("STORE_USER", response.data);
      router.replace('/tenants');
    }).catch(err => {
      if (err.response.status === 400 || err.response.status === 401) {
        commit("STORE_USER", {
          user: {},
          jwt: ""
        });
        router.replace("/login")
      }
    });
  },
  async login({ commit }, token) {
    await axios.post(`${rootUrls.URL}/users/login`, {
      token: token
    }).then(response => {
      window.sessionStorage.setItem("token", response.data.jwt)
      commit("STORE_USER", response.data)
    }).catch(err => {
      console.log(err);
    });
  },
  async getUsers({ commit, rootState }) {
    await axios.get(`${rootUrls.URL}/users/list`, {
      headers: {
        Authorization: `Bearer ${rootState.users.jwt}`
      }
    }).then(response => {
      commit("STORE_USERS", response.data)
    })
  },
  async deleteUser({ rootState }, email_address) {
    await axios.delete(`${rootUrls.URL}/users/delete`, {
      data: { email_address: email_address },
      headers: {
        Authorization: `Bearer ${rootState.users.jwt}`
      }
    }).then(response => {
      console.log(response)
    }).catch(error => {
      // It's a good practice to handle errors
      console.error("There was an error deleting the user:", error);
    });
},
  async addNewUser({rootState}, data){
    await axios.post(`${rootUrls.URL}/users/create`, data, {
      headers: {
        Authorization: `Bearer ${rootState.users.jwt}`
      }
    }).then(response => {
      console.log(response)
    })
  },
  logout() { 
    window.localStorage.removeItem("token")
}
}
const mutations = {
  STORE_USER: (state, data) => {
    if (data.jwt) {
      state.jwt = data.jwt;
    }
    if (data.user) {
      state.user = data.user;
    }
  },
  STORE_USERS: (state,  data) => {
    state.users = data;
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
