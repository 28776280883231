<template>
  <v-navigation-drawer
      app
      width="100"
      dark
      mobile-breakpoint="600"
  ><div class="d-none d-sm-flex justify-center ma-4">
      <router-link
          style="height: 48px;"
          to="/">
        <img
            height="40"
            src="../assets/logo_white.png"
            alt="logo"/>
      </router-link>
    </div>
    <v-list class="pa-0">
      <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          router
          :to="item.route"
          active-class="nav-bg-color"
      >
        <v-list-item-content>
          <v-icon>
            {{ item.icon }}
          </v-icon>
          <v-list-item-title class="smaller font-weight-medium text-center">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list class="pa-0 bottom-items">
      <v-list-item
          v-for="item in bottomItems"
          :key="item.title"
          link
          router
          :to="item.route"
      >
        <v-list-item-content>
          <v-icon>
            {{ item.icon }}
          </v-icon>
          <v-list-item-title class="smaller font-weight-medium text-center">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script src="https://apis.google.com/js/platform.js?onload=onLoad" async defer></script>
<script>
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions(["toggleSettingsModal"]),
  },
  data() {
    return {
      items: [{
        title: 'Tenants',
        icon: 'mdi-domain',
        route: '/tenants'
      },
        {
          title: 'Users',
          icon: 'mdi-account-multiple',
          route: '/users'
        },
        {
          title: 'Connections',
          icon: 'mdi-connection',
          route: '/connections'
        },
        
        ],
      bottomItems: [{
        title: 'Help',
        icon: 'mdi-help-box',
        route: '/help'
      },
        {
          title: 'Settings',
          icon: 'mdi-cog',
          route: '/settings'
        }]
    };
  },
};
</script>

<style lang="scss" scoped>
.bottom-items {
  position: absolute !important;
  bottom: 0;
}
.smaller {
  font-size: 0.75em !important;
}
</style>
