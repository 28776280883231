<template>
  <div>
    <v-container fluid>
    <v-dialog v-model="addModal" width="600">
      <v-card>
        <v-card-title>Add new user</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="addNewUser">
            <v-text-field label="User email" v-model="form.email_address" :rules="emailRules">
            </v-text-field>
            <v-checkbox label="Admin" v-model="form.admin" :value="true">
            </v-checkbox>
            <div class="d-flex justify-end">
                <v-btn small right color="grey-lighten-2" @click="addModal = false">cancel</v-btn>
                <v-btn small class="ml-4" right color="primary" type="submit">Submit</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" persistent max-width="600" >
      <v-card class="pa-4">
        <v-card-title class="text-h5">Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete {{ formDelete.email_address }}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small elevation="0" color="gray"  @click="deleteDialog = false">Cancel</v-btn>
          <v-btn small elevation="0" color="primary"  @click="deleteUser">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
        :headers="headers"
        :items="users"
        :search="searchQuery"
        :items-per-page="100"
        :page.sync="pagination.page"
        hide-default-footer
        class="elevation-1">
        <template v-slot:[`item.actions`]="{ item }">
        <v-btn elevation="0" color="primary" x-small @click="promptDelete(item.email_address)">
            delete
        </v-btn>
      </template>
        <template v-slot:top>
        <v-text-field
          label="Search"
          class="pa-4"
          outlined
          v-model="searchQuery"
          @input="updateSearchQuery"
          dense
          slot="top"
        ></v-text-field>
      </template>
      <template v-slot:footer="{ props }">
        <v-data-footer :pagination="props.pagination" :options="props.options" disable-items-per-page @update:options="updatePagination" ></v-data-footer>
      </template>
    </v-data-table>
    <v-btn fab class="fab-bottom mb-16" bottom color="primary" @click="addModal = !addModal"><v-icon>mdi-plus</v-icon></v-btn>
  </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({
    searchQuery: "",
    formDelete:{
        email_address: null,
    },
    emailRules: [
    v => !!v || 'Email is required',
    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ],
    addModal: false,
    deleteDialog: false,
    userToDelete: null,
    form: {
      admin: false,
      email_address: ""
    },
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id'
      },
      {
        text: 'Email Address',
        align: 'start',
        sortable: false,
        value: 'email_address'
      },
      {
        text: 'Updated at',
        align: 'start',
        sortable: false,
        value: 'updated_at'
      },
      {
        text: 'Created at',
        align: 'start',
        sortable: false,
        value: 'created_at'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'start',
    },
    ]
  }),
  mounted() {
    this.getTenants()
    this.getUsers()
  },
  watch: {
    searchQuery() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.runSearch();
      }, 400);
    },
  },
 
  methods: {
    ...mapActions({
      getTenants: "tenants/fetchTenants",
      createTenant: "tenants/createTenant",
      getUsers: "users/getUsers",
      addUser: "users/addNewUser",
      deleteUserAction: "users/deleteUser"
    }),
    promptDelete(item) {
      this.formDelete.email_address = item; 
      this.deleteDialog = true;
      this.userToDelete = null;
    },
    updatePagination(options) {
      this.getTenants({page: options.page})
    },
    addNewUser() {
      this.addUser(this.form).then(() => {
        this.addModal = false;
        this.getUsers()
      })
      this.form.email_address = "",
      this.form.admin = false
    },
    deleteUser() {
  // Assuming your API expects just the email address for deletion
  this.deleteUserAction(this.formDelete.email_address).then(() => {
    this.deleteDialog = false;
    this.userToDelete = null;
    this.getUsers();
  }).catch(error => {
    // Handling potential errors here is a good practice
    console.error("Failed to delete user:", error);
  });
},
    

    runSearch() {
      if (this.searchQuery === "") {
        return this.$store.commit("tenants/SET_SEARCH_TENANTS","")
      } else {
        return this.$store.commit("tenants/SET_SEARCH_TENANTS", this.searchQuery)
      }
    },
    updateSearchQuery(val) {
    this.searchQuery = val;
  },
  },
  computed: {
    ...mapGetters({
        tenants: "tenants/getTenants",
        users: "users/getUsers",
        pagination: "tenants/getTenantsPagination"
      })
  },
  
}
</script>

<style scoped lang="scss">
.search-bar{
  height: 5%;
}
.fab-bottom {
  position: fixed;
  margin-right: 16px;
  bottom: 16px;
  right: 0;
  z-index: 5;
}
</style>
