import axios from "axios"
import {rootUrls} from "@/assets/_constants";

const state = {
  items: [],
  pagination: {},
}

const getters = {
  getConnections(state) {
    return state.items
  },
  getConnectionsPagination(state) {
    return state.pagination
  }
}

const actions = {
  async fetchConnections({ commit, rootState }, params) {
    await axios.get(`${rootUrls.URL}/connections`, {
      params: params,
      headers: {
        Authorization: `Bearer ${rootState.users.jwt}`
      }
    }).then(response => {
      commit("STORE_CONNECTIONS", response.data)
    })
  }
}

const mutations = {
  STORE_CONNECTIONS: (state, data) => {
    state.items = data.connections;
    state.pagination = data.pagination;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
