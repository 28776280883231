<template>
  <v-app v-if="this.$route.name !== 'Login'">
    <LeftSidebar></LeftSidebar>
    <v-main class="main overflow-y-auto scroll-right-md-y scroll-right-lg-y">
      <div class="pt-4 mx-4 pb-6 mt-n1 grey--text text--darken-3 font-weight-bold">
        <span>{{ this.$route.name }}</span>
      </div>
      <router-view></router-view>
    </v-main>
    <!--<v-progress-linear indeterminate v-if="loading"></v-progress-linear>-->
  </v-app>
  <v-app v-else>
    <router-view></router-view>
  </v-app>
</template>

<script>
import LeftSidebar from '@/components/LeftSidebar'

export default {
  name: 'App',
  components: {
    LeftSidebar
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
.title {
  margin-top: 22px !important;
}

body {
  overflow: hidden;
  height: 100vh;
}

.main {
  max-height: 100vh;
  background-color: #f5f5f5 !important;

}

@media only screen and (max-width: 600px) {
  .container-padding {
    padding: 0 !important;
  }
}
.v-overlay {
  left: 100px !important;
  z-index: 1 !important;
}
.alert--bottom-fixed {
  width: 100%;
  z-index: 999;
  bottom: 0px;
  position: fixed !important;
  left: 0px;
}
</style>
