<template>
  <v-container fluid>
    <v-data-table
        :headers="headers"
        :items="connections"
        class="elevation-1">
    </v-data-table>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id'
      },
      {
        text: 'UUID',
        align: 'start',
        sortable: false,
        value: 'uuid'
      },
      {
        text: 'Tenant name',
        align: 'start',
        sortable: false,
        value: 'tenant_name'
      },
      {
        text: 'DeviceUUID',
        align: 'start',
        sortable: false,
        value: 'device_uuid'
      },
      {
        text: 'Started at',
        align: 'start',
        sortable: false,
        value: 'started_at'
      },
      {
        text: 'Closed at',
        align: 'start',
        sortable: false,
        value: 'closed_at'
      },
      {
        text: 'Connection time',
        align: 'start',
        sortable: false,
        value: 'connection_time'
      }]
  }),
  mounted() {
    this.getConnections({page: 1})
  },
  methods: {
    ...mapActions({
      getConnections: "connections/fetchConnections"
    }),
    updatePagination(options) {
      this.getConnections({page: options.page})
    }
  },
  computed: {
    ...mapGetters({
      connections: "connections/getConnections",
      pagination: "connections/getConnectionsPagination"
    })
  },
}
</script>

<style scoped>

</style>
