import axios from "axios"
import {rootUrls} from "@/assets/_constants";

const state = {
  items: [],
  pagination: {},
  searchTenants: "",
}

const getters = {
  getTenants(state) {
    if(state.searchTenants) {
      return state.items.filter(item => {
        return item.name.toLowerCase().includes(state.searchTenants.toLowerCase())
      })
    } else {
      return state.items
    }
  },
  getTenantsPagination(state) {
      return state.pagination
  }
}

const actions = {
  async fetchTenants({ commit, rootState }, params) {
    await axios.get(`${rootUrls.URL}/tenants`, {
      params: params,
      headers: {
        Authorization: `Bearer ${rootState.users.jwt}`
      }
    }).then(response => {
      commit("STORE_TENANTS", response.data)
      
    })
  },
  async createTenant({rootState}, data) {
    await axios.post(`${rootUrls.URL}/tenants`, data, {
      headers: {
        Authorization: `Bearer ${rootState.users.jwt}`
      }
    }).then(response => {
      return response.data;
    });
  },
  async searchTenant({commit}) {
    commit("SEARCH_TENANTS", state.searchTenants)
  }

}

const mutations = {
  STORE_TENANTS: (state, data) => {
    state.items = data.connections;
    state.pagination = data.pagination;
  },
  PUSH_TENANT: (state, value) => {
    state.items.push(value)
  },
  SET_SEARCH_TENANTS: (state, value) => {
    state.searchTenants = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
