<template>
  <div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Home',
  mounted() {
    if (!this.token) {
      this.$router.replace('/login')
    } else {
      this.getSelf();
    }
  },
  methods: {
    ...mapActions({"getSelf": "users/getSelf"})
  },
  computed: {
    ...mapGetters({"user": "users/getUser", "token": "users/getToken"})
  },
}
</script>
