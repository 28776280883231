import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common';
import tenants from './modules/tenants';
import users from './modules/users';
import connections from './modules/connections';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    tenants,
    users,
    connections
  }
})
